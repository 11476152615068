<template>
  <div class="flex flex-col w-full h-full select-none timeline-grid-area outline-0">
    <TimelinePanel
      v-if="isReady"
      :key="timelineId"
      :viewer="viewer"
      @view-module="$emit('view-module', $event)"
      @timeline-changed="$emit('timeline-changed', $event)"
    />
    <template v-else>
      <div class="flex justify-center flex-1 item-center align-center">
        <div class="py-12 text-gray-200">
          <fa-icon
            icon="spinner"
            spin
            class="mr-2"
          /> Loading Sequence
        </div>
      </div>
    </template>
  </div>
</template>
<script>
const TimelinePanel = () => import('@components/editor/timelineMerged/TimelinePanel')

export default {
  name: 'TimelineBar',

  components: {
    TimelinePanel
  },

  props: {
    viewer: {
      type: Object,
      required: true
    }
  },

  computed: {
    previztimeline () {
      if (this.hasTimeline) return this.viewer.core.sequence.timeline
      return null
    },

    hasTimeline () {
      return this.viewer && this.viewer.core.sequence && this.viewer.core.sequence.timeline
    },

    timelineId () {
      if (!this.isReady) return null
      return this.viewer.core.sequence.id
    },

    isReady () {
      return this.previztimeline !== null
    }
  }
}
</script>
